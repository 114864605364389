@if (article$ | async; as assetsale) {

@if (assetsale.body?.['sale']; as sale) {
<ng-scrollbar class="ngr-scrollbar"  visibility="visible">
    <h2>{{assetsale.description}} – {{sale.assetsale_name}}</h2>
    <ul class="list-unstyled">
        <li><strong>Type</strong> {{sale.assetsale_type}}</li>
        <li><strong>Going Concern/Liquidation:</strong> {{sale.assetsale_going_concern_liquidation}}</li>
        <li><strong>Status:</strong> {{sale.assetsale_status}}</li>
        <li><strong>Aggregated Sale Proceeds:</strong> {{sale.total_consideration_amount_derived}}</li>
    </ul>

    <asset-sale-view [sale]="sale" [biddingprocedures]="assetsale.body['biddingprocedures']" [assets]="assetsale.body['assets']"></asset-sale-view>
</ng-scrollbar>
}

}